import React from 'react';
import HeadlineInfo from '../HeadlineInfo';
import Configure from '../Configure';
import Enhance from '../Enhance';
import Action from '../Action';
import { useTranslation } from 'react-i18next';

export default function SellBox({category}) {
        const { t } = useTranslation();

	return (
		<div>
			<HeadlineInfo category={category} />
			<Configure />
			<Enhance category={category} />
			<p><a style={{color: '#0084A9', border: 0, textDecoration: 'underline'}} href="#witb">{t("Show what's in the box")}</a></p>
			<Action />
		</div>
	);
}

