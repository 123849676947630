import {t} from "i18next";

export function getCsvHeaders() {
    return [
        {label: "Product name", key: "name"},
        {label: "SKU", key: "sku"},
        {label: "Description", key: "description"},
        {label: t('List price'), key: "price"},
    ]
}

export function getCsvData(tables) {
    const rows = []
    for (const table of tables) {
        rows.push({name: table.category})
        rows.push({})

        for (const row of table.data) {
            rows.push({
                name: row.name,
                sku: row.sku,
                description: row.raw_description,
                price: row.raw_price
            })
        }
    }

    return rows;
}
