import React, {useMemo} from 'react'
import { useTable } from 'react-table';
import {t} from "i18next";

export function Table({ heading, data }) {
    const columns = getColumns()

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    })

    return (
        <div className="container">
            <h2>{heading}</h2>
            <table {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>

            <style jsx>{`
            table {
              width: 100%;
              max-width: 1200px;
              border-spacing: 0;
            }

            th, td {
              min-width: 4rem;
              text-align: left;
              padding: .5rem;
              vertical-align: top;
            }

            th {
              border: none;
              color: white;
              background-color: #005C75;
              padding: 6px 8px;
              margin: 0;
              text-align: left;
            }

            td:nth-child(1) {
              // Name
              width: 35%;
            }
            td:nth-child(2) {
              // SKU
              width: 15%;
            }
            td:nth-child(4), th:nth-child(4) {
              // Price
              width: 15%;
              text-align: right;
            }
            tr:nth-child(even) {
              background-color: #f5f5f5;
            }
            `}</style>
        </div>
    )
}

function getColumns() {
    return useMemo(
        () => [
            {
                id: 'name',
                Header: 'Product name',
                accessor: 'name',
            },
            {
                id: 'sku',
                Header: 'SKU',
                accessor: 'sku',
            },
            {
                id: 'description',
                Header: 'Description',
                accessor: 'description',
            },
            {
                id: 'price',
                Header: t('List Price'),
                accessor: 'price',
            },
        ],
        []
    )
}
