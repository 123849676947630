import React from 'react'
import Price from "../Ui/Price";
import {isBundle, isConfigurable, isSimple} from "../../utils/product";
import patches from "../Ui/Price/intlPatches";
import removeMd from 'remove-markdown';
import breaks from 'remark-breaks'
import ReactMarkdown from "react-markdown";

export default function getData(products, attributeData) {
    let rows = [];
    for (const product of products) {
        const newRows = createRows(product, attributeData);
        if (newRows) {
            for (const newRow of newRows) {
                rows.push(newRow)
            }
        }
    }

    return rows;
}

function createRows(product, attributeData) {
    if (isConfigurable(product)) {
        return createConfigurableProductRows(product, attributeData)
    }
    if (isBundle(product)) {
        return createBundleProductRows(product);
    }

    return [createSimpleProductRow(product)]
}

function createSimpleProductRow(product) {
    return getDefaultRow(product)
}

function createConfigurableProductRows(product, attributeData) {
    for (const variant of product.variants) {

    }

    return getDisplaySku(product).startsWith('FLO-PRO')
        ? createPromethionFlowcellRows(product, attributeData)
        : createConfigurableDefaultProductRows(product, attributeData)
}

function createConfigurableDefaultProductRows(product, attributeData) {
    const rows = []
    rows.push(getDefaultRow(product, '', true))

    const sortedChildren = product.variants.slice().sort((a, b) => {
        return a.product.price.regularPrice.amount.value - b.product.price.regularPrice.amount.value
    })

    for (const child of sortedChildren) {
        rows.push(getConfigurableChildRow(child.product,  attributeData))
    }

    return rows
}
function createPromethionFlowcellRows(product, attributeData) {
    const rows = []

    const sortedChildren = product.variants.slice().sort((a, b) => {
        return a.product.price.regularPrice.amount.value - b.product.price.regularPrice.amount.value
    })

    const annualPacks = sortedChildren.filter(pack => !pack.product.sku.includes('TP'));
    if (annualPacks.length) {
        rows.push(getDefaultRow(product, '', true));
        annualPacks.forEach(child => rows.push(getConfigurableChildRow(child.product, attributeData)));
    }

    const topupPacks = sortedChildren.filter(pack => pack.product.sku.includes('TP'));
    if (topupPacks.length) {
        rows.push(getDefaultRow(product, 'Top-Ups', true));
        topupPacks.forEach(child => rows.push(getConfigurableChildRow(child.product)));
    }

    return rows
}

function createBundleProductRows(product) {
    const rows = []
    rows.push(getDefaultRow(product))

    return rows
}

function getPrice(product, attributeData = null) {
    const {amount, expiry} = getPriceData(product, attributeData);

    return <>
        <Price currencyCode={amount.currency} value={amount.value} />
        {expiry && <><br /> <span>&nbsp; (expires {expiry})</span></>}
    </>;
}

function getPriceData(product, attributeData = null) {
    let amount = {};
    let expiry = null;

    if (product.ont_custom_price) {
        amount = product.ont_custom_price;
        expiry = product.ont_custom_price.expires_at;
    } else {
        amount = {...product.price.regularPrice.amount};

        if (product.ont_tier_price && product.ont_tier_price.tier_unit_price > 0) {
            const packSize = getPackSizeByCode(product.ont_pack_size, attributeData);

            if (packSize) {
                let tierValue = product.ont_tier_price.tier_unit_price * packSize;
                if (tierValue < amount['value']) {
                    amount['value'] = tierValue;
                }
                expiry = product.ont_tier_price.tier_expiry;
            }
        }
    }

    if (expiry) {
        const date = new Date(expiry);
        expiry = date.toISOString().split('T')[0]
    }

    return {amount: amount, expiry: expiry};
}

function getPackSizeByCode(packSizeCode, attributeData) {
    if (!(packSizeCode && attributeData)) {
        return null;
    }

    const psData = attributeData.items.find(item =>
        item.attribute_code === 'ont_pack_size'
    )
    if (!psData) {
        return null;
    }

    const option = psData.attribute_options.find(o =>
        o.value == packSizeCode
    );
    return option?.label ?? null;
}

function getDisplaySku(product) {
    return product.ont_mask_sku ?? product.sku;
}

function isInCategory(category, product) {
    return true;
}

function getDescription(product) {
    const description = getDescriptionText(product)
    return <ReactMarkdown plugins={[breaks]}>{description}</ReactMarkdown>;
}

function getDefaultRow(product, nameSuffix = '', skipPrice = false) {
     let name = product.name;
     if (nameSuffix) {
         name += ' ' + nameSuffix
     }

     return {
         sku: getDisplaySku(product),
         name: name,
         description: getDescription(product),
         price: skipPrice ? '' : getPrice(product),
         raw_description: getRawText(getDescriptionText(product)),
         raw_price: skipPrice ? '' : getRawPrice(product),
     };
}

function getConfigurableChildRow(product, attributeData) {
    return {
        sku: product.sku,
        name: '',
        description: product.name,
        raw_description: product.name,
        price: getPrice(product, attributeData),
        raw_price: getRawPrice(product, attributeData)
    }
}

function getRawPrice(product, attributeData = null) {
    const {amount, expiry} = getPriceData(product, attributeData);

    const value = amount.value;
    const currencyCode = amount.currency;

    const parts = patches.toParts.call(
        new Intl.NumberFormat("en-GB", {
            style: 'currency',
            currency: currencyCode
        }),
        value
    );

    let price = parts.map(part => part.value).join('');
    if (expiry) {
        price += ` (expires ${expiry})`;
    }

    return price;
}

function getRawText(text) {
    return removeMd(text)
        .replace('> ', '')
        .replace(/^\|(.*)$/, '');
}

function getDescriptionText(product) {
    let description = '';

    const isDevice = product.categories.some(category => {
        return category.url_path?.match(/devices/)
    })

    if (product.ont_price_list_description) {
        description = product.ont_price_list_description;
    }
    else if (isDevice) {
        description = product.ont_whats_in_the_box ?? product.short_description.html;
    }
    else {
        description = product.short_description.html;
    }

    description = description
        // .replace(/\n/gi, ' \n')
        .replace("# Package Includes", "### Package Includes")
        .replace("# Package includes", "### Package Includes")

    return description;
}
